<script setup lang="ts">
import RatingsPage from '@/views/RatingsPage.vue';
import { onMounted, provide, toRefs } from 'vue';
import { initData } from '@/modules/rating-detail/inits';
import QueryString from 'qs';
import { queryKey } from '@/shared/constants/ProviderKeys';
import { UserResponse } from '@shared/types/User';
import { Statistic } from '@/shared/service/StatisticService';

const props = defineProps<{
  data: UserResponse;
  query: QueryString.ParsedQs;
  appName: string;
}>();

const { data, query, appName } = toRefs(props);
initData(data.value);

const statistic = new Statistic(data.value, appName.value);

provide(queryKey, query.value);

onMounted(() => {
  statistic.sendOnPageLoad();
});
</script>

<template>
  <RatingsPage />
</template>

<style lang="scss">
@import '@anwalt.de/tailwind-design-system/src/index.css';
@import '@anwalt.de/tailwind-design-system/dist/style.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';
</style>
