<script setup lang="ts">
import RatingListHeader from '@/modules/rating-detail/components/RatingList/RatingListHeader.vue';
import RatingList from '@/modules/rating-detail/components/RatingList/RatingList.vue';
import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { storeToRefs } from 'pinia';

const { rating } = storeToRefs(useRatingStore());
</script>

<template>
  <section v-if="rating.ratingCount" id="ratingList" class="rounded bg-white p-5">
    <RatingListHeader />
    <RatingList />
  </section>
</template>
