<script lang="ts" setup>
import { AnwPagination } from '@anwalt.de/tailwind-design-system';
import { usePagination } from '@/shared/composables/pagination';
import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { storeToRefs } from 'pinia';

const ratingStore = useRatingStore();
const { rating } = storeToRefs(ratingStore);
const { page, maxPage, setPageQuery } = usePagination(rating.value.ratingCount);

const handleUpdate = (nextPage: number) => {
  window.scrollTo(0, 0);
  ratingStore.updateRatingList(nextPage);
  setPageQuery(nextPage);
};
</script>

<template>
  <section v-if="rating.ratingCount" id="ratingPagination" class="rounded bg-white p-5">
    <AnwPagination @update:current-page="handleUpdate" :current-page="page" :number-of-pages="maxPage" />
  </section>
</template>
