<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SectionHeader from '@/shared/components/SectionHeader.vue';
const { t } = useI18n();
</script>

<template>
  <div class="sticky top-0 pt-4 -mt-4 bg-white z-10">
    <SectionHeader :headline="t('ratingsHeading')" tag="h2" class="sticky top-0 md:flex justify-between bg-white" />
  </div>
</template>
