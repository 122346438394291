<script setup lang="ts">
import { computed } from 'vue';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import ProfessionContent from '@/shared/components/ProfessionContent.vue';
import { useSettingsStore } from '@/modules/profile-page/stores/SettingsStore';

const { profile } = useLawyerStore();
const { admissionDate } = useSettingsStore();
const { profileProductId } = useProfileProductId();
const { getTranslationByProductId } = TranslationHelper();
const profileProductIdText = computed(() =>
  getTranslationByProductId('profileProductIdText', profileProductId, { profession: profile.profession })
);
</script>

<template>
  <ProfessionContent
    :dataTestId="'rating-detail-form-verified-badge'"
    :positioningClass="'relative !-left-36'"
    :profession="profile.profession"
    :isVerified="admissionDate"
    :profileProductIdText="profileProductIdText" />
</template>
