import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { defaultRating, type RatingSummary } from '@shared/types/RatingSummary';
import { PaginationHelper } from '@shared/helper/PaginationHelper';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { Review, ReviewList } from '@shared/types/Review';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER } from '@shared/constants/ProfileProductId';
import { RatingListQuery } from '@shared/service/graphql/rating/RatingListQuery';
import { MAX_PER_PAGE } from '@shared/constants/Pagination';

export const useRatingStore = defineStore('rating-detail-rating-store', () => {
  const rating = ref<RatingSummary>(defaultRating);
  const reviewList = ref<ReviewList>([]);

  const client = new ApolloClient({
    link: ApolloLink.from([createHttpLink({ uri: import.meta.env.VITE_GRAPHQL_API_URL })]),
    cache: new InMemoryCache(),
  });
  const { userId } = storeToRefs(useUserStore());
  const { profileProductId } = storeToRefs(useProfileProductId());
  const type = computed(() => (profileProductId.value === LAWYER ? 'Lawyer' : 'Company'));

  const loading = ref(false);

  const updateRatingList = async (page: number) => {
    try {
      loading.value = true;
      reviewList.value = (
        await client.query({
          query: RatingListQuery,
          variables: {
            offset: PaginationHelper().getOffsetFromPage(page),
            type: type.value,
            userId: userId.value,
            limit: MAX_PER_PAGE,
          },
        })
      )?.data.reviewList as Review[];
    } catch (e) {
      console.log(e);
    } finally {
      loading.value = false;
    }
  };

  return {
    rating,
    reviewList,
    updateRatingList,
    loading,
  };
});
