<script lang="ts" setup>
import { AnwInitialsBadge, AnwRatingStars, AnwBadge, AnwAvatar } from '@anwalt.de/tailwind-design-system';
import { Review } from '@shared/types/Review';
import { DateHelper } from '@/shared/helper/DateHelper';
import { useI18n } from 'vue-i18n';
import { useProfileStore } from '@/modules/rating-detail/stores/ProfileStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { storeToRefs } from 'pinia';
import { COMPANY } from '@shared/constants/ProfileProductId';
import { ImageHelper } from '@shared/helper/ImageHelper';
import { UrlHelper } from '@/shared/helper/UrlHelper';
import { usePeriodInitials } from '@/shared/composables/periodInitials';

const { uri } = useProfileStore();
const { profileProductId } = storeToRefs(useProfileProductId());
const { formatDate, formatHour } = DateHelper();
const { getProfileUrl } = UrlHelper();
const { t } = useI18n();

defineProps<{
  review: Review;
  index: number;
}>();

const getActorImageThumbnail = (actorImage: string) => {
  const origin = new URL(uri).origin;
  return origin + ImageHelper().generateLogoImage(actorImage);
};
</script>
<template>
  <div :data-test-id="`${index}-rating-item`" class="w-full p-5 bg-neutral-50 rounded-lg border border-neutral-100">
    <div class="sm:flex sm:items-center">
      <div class="flex items-center mb-2 sm:mb-0">
        <AnwInitialsBadge :content="review.reviewer" class="mr-3.5" />
        <AnwRatingStars :rating-average="review.rating" class="mr-3.5" />
      </div>
      <p :data-test-id="`${index}-description`" class="anw-text-p1 text-neutral-500 mr-3.5">
        {{
          t('reviewedFromAt', {
            initials: usePeriodInitials(review.reviewer),
            date: formatDate(review.displayDate),
            hour: formatHour(review.displayDate),
          })
        }}
        <span v-if="review.ratedActorName && profileProductId === COMPANY">
          {{ t('ratingFor') }}
          <a
            :href="getProfileUrl(review.shortUrl)"
            :title="review.ratedActorName"
            class="anw-text-link text-neutral-500 hover:text-neutral-500 cursor-pointer">
            {{ review.ratedActorName }}
          </a>
        </span>
      </p>
    </div>
    <div class="lg:pl-14 mt-1">
      <div>
        <span class="inline-block mr-3 text-slate-900 text-base font-semibold leading-normal mb-1.5">{{
          review.header
        }}</span>
        <div v-if="review.practiceAreas" class="inline-flex mb-2">
          <AnwBadge v-for="practiceArea in review.practiceAreas" :key="practiceArea">{{ practiceArea }}</AnwBadge>
        </div>
      </div>

      <div class="text-slate-700 text-base font-normal leading-normal" v-html="review.message"></div>
      <div
        :data-test-id="`${index}-rating-item-response`"
        v-if="review.response.text"
        class="bg-white p-2 rounded-lg mt-2">
        <div class="mb-3 flex justify-items-start">
          <div class="relative flex gap-4 items-center">
            <a :href="getProfileUrl(review.response.shortUrl)" class="anw-text-link" :title="review.response.actorName">
              <figure class="shrink-0">
                <AnwAvatar
                  :alt="review.response.actorName"
                  :aspect="'auto'"
                  :src="getActorImageThumbnail(review.response.actorImage) + '?side-length=40'"
                  size="md" />
              </figure>
            </a>
            <div :data-test-id="`${index}-response-heading`" class="text-sm font-semibold">
              {{ t('ratingCommentHeading') }}

              <a class="anw-text-link" :href="getProfileUrl(review.response.shortUrl)">{{
                review.response.actorName
              }}</a>
            </div>
          </div>
        </div>
        <div class="text-neutral-700" v-html="review.response.text"></div>
      </div>
    </div>
  </div>
</template>
