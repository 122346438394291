<script lang="ts" setup>
import { AnwButton, AnwInput, AnwSelect, AnwTextarea } from '@anwalt.de/tailwind-design-system';
import { SetRating } from '@/modules/rating-detail/components/Base/Form';
import {
  DEFAULT_PRACTICE_AREA,
  MAX_VALUE_TEXT_TITLE_LIMIT,
  MAX_VALUE_TEXTAREA_MESSAGE,
} from '@/modules/rating-detail/constants/Forms';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileStore } from '@/modules/rating-detail/stores/ProfileStore';
import { usePracticeAreasStore } from '@/shared/stores/PracticeAreasStore';
import { computed, ref, watch } from 'vue';
import { RATING_FORM_RATE_LINK } from '@/modules/profile-page/constants/Links';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';

const { t } = useI18n();
const { profileProductId } = storeToRefs(useProfileProductId());
const { profile } = storeToRefs(useLawyerStore());
const { uri } = storeToRefs(useProfileStore());
const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();
const setRatingLabel = getTranslationByProductId('setRatingHeading', profileProductId.value, {
  n: getGenderValueForTranslation(profile.value.sex),
});

type FormData = {
  practiceAreaId: string | number;
  mark: number;
  title: string;
  text: string;
};
const formData = ref<FormData>({
  practiceAreaId: '',
  mark: 0,
  title: '',
  text: '',
});

const { practiceAreas } = storeToRefs(usePracticeAreasStore());
const options = computed(() => {
  return [DEFAULT_PRACTICE_AREA, ...practiceAreas.value]
    .map((practiceArea) => ({
      value: practiceArea.id,
      label: practiceArea.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label, 'de-DE', { sensitivity: 'base' }));
});
const practiceAreaId = ref(DEFAULT_PRACTICE_AREA.id);
watch(practiceAreaId, (newPracticeAreaId) => {
  if (newPracticeAreaId === DEFAULT_PRACTICE_AREA.id) {
    formData.value.practiceAreaId = '';
    return;
  }
  formData.value.practiceAreaId = newPracticeAreaId;
});

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.LABEL]: t('eventLabelRatingPage'),
  [GA_ATRIBUTES.ACTION]: t('eventActionClickRatingFormButton'),
});
</script>
<template>
  <div class="px-4 pb-4 pt-1">
    <form method="post" :action="uri + RATING_FORM_RATE_LINK">
      <div class="mb-6">
        <SetRating name="mark" :set-rating-label="setRatingLabel" v-model="formData.mark" />
      </div>
      <p class="mb-3 text-neutral-700">{{ t('labelTitleRatingPracticeAreas') }}</p>
      <AnwSelect
        :label="t('labelPracticeAreas')"
        :options="options"
        data-test-id="rating-detail-form-select-practice-area"
        v-model="practiceAreaId" />
      <input type="number" :value="formData.practiceAreaId" name="practiceAreaId" class="hidden" />
      <AnwInput
        id="rating-title"
        name="title"
        :max-length="MAX_VALUE_TEXT_TITLE_LIMIT"
        :label="t('labelYourRatingTitle')"
        data-test-id="rating-detail-form-rating-title"
        v-model="formData.title" />
      <AnwTextarea
        id="description"
        :rows="5"
        name="text"
        :max-length="MAX_VALUE_TEXTAREA_MESSAGE"
        :label="t('labelTextareaRating')"
        data-test-id="rating-detail-form-rating-text"
        v-model="formData.text" />
      <div class="flex">
        <AnwButton
          type="submit"
          :label="t('formProfileRatingSubmit')"
          v-bind="dataAttributesGA"
          data-submit-button
          data-test-id="rating-detail-form-submit-button"
          name="submitForm"
          class="grow" />
      </div>
    </form>
  </div>
</template>
