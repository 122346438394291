import { useBreadCrumbsStore } from '@/shared/stores/BreadcrumbsStore';
import { useProfileStore } from '../stores/ProfileStore';
import { i18n } from '@shared/locales';

const { t } = i18n.global;

export const initBreadcrumbs = () => {
  const breadcrumbsStore = useBreadCrumbsStore();
  const { uri, displayedName } = useProfileStore();

  breadcrumbsStore.$patch((state) => {
    state.breadcrumbsPaths.push({ url: uri, text: displayedName });
    state.breadcrumbsPaths.push({ url: '', text: t('breadcrumbsRatingsPath') });

    state.breadcrumbsLabel = displayedName;
  });
};
