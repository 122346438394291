<script lang="ts" setup>
import { computed, Ref } from 'vue';

interface Props {
  modelValue?: number;
  size?: string;
  maxRating?: number;
  errorMessage?: string | Ref<string>;
  label?: string;
  name?: string;
  setRatingLabel: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: 0,
  size: '36',
  maxRating: 5,
  name: 'group',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const value = computed({
  get: () => {
    return props.modelValue;
  },
  set: (value) => {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <fieldset>
    <legend v-if="setRatingLabel" for="anw-rating" class="block mb-3 text-neutral-700">{{ setRatingLabel }}</legend>
    <div class="anw-stars-wrapper flex align-center justify-content-center w-fit">
      <label
        :for="'rating-star-mark-' + i"
        v-for="i in maxRating"
        :key="i"
        class="text-neutral-300 hover:text-warning-400"
        :class="{ 'text-warning-400': i <= modelValue }"
        :data-test-id="i">
        <span class="sr-only">Bewerten Sie mit {{ i }}</span>
        <input
          :id="'rating-star-mark-' + i"
          :data-test-id="'rating-star-mark-' + i"
          type="radio"
          :name="name"
          class="sr-only peer"
          :value="i"
          v-model="value" />
        <svg
          :width="size"
          :height="size"
          :viewBox="`0 0 ${size} ${size}`"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          class="peer-focus-visible:outline"
          aria-hidden="true">
          <path
            d="M18 0L22.4966 11.811L35.119 12.4377L25.2756 20.364L28.5801 32.5623L18 25.65L7.41987 32.5623L10.7244 20.364L0.880983 12.4377L13.5034 11.811L18 0Z" />
        </svg>
      </label>
    </div>
    <div v-if="errorMessage" class="anw-invalid-feedback">{{ errorMessage }}</div>
  </fieldset>
</template>
