import { UserResponse } from '@shared/types/User';
import { initUser } from './InitUser';
import { initProfileType } from './InitProfileType';
import { initContactDetail } from './InitContactDetail';
import { initRating } from '@/modules/rating-detail/inits/InitRating';
import { ProfileProductHelper } from '@shared/helper/ProfileProductHelper';
import { initBreadcrumbs } from './InitBreadcrumbs';
import { initPracticeAreas } from '@/shared/inits/InitPracticeAreas';

const initData = (userData: UserResponse) => {
  const { getProfileProductData, getProfileProductId } = ProfileProductHelper(userData);
  const profileProductId = getProfileProductId();
  const profileData = getProfileProductData(profileProductId);

  if (profileData) {
    initProfileType(profileProductId, profileData);
    initUser(profileData);
    initContactDetail(profileData.contactDetailList);
    initBreadcrumbs();
    initPracticeAreas(profileData.practiceAreaList);
  }

  initRating(userData.ratingSummary, userData.reviewList);
};

export { initData };
