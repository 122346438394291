import { useSettingsStore } from '@/modules/profile-page/stores/SettingsStore';
import Lawyer from 'shared/types/Lawyer';
import Company from 'shared/types/Company';
import { useProfileStore } from '@/modules/rating-detail/stores/ProfileStore';

export const initUser = (userData: Lawyer | Company) => {
  useProfileStore().$patch((state) => {
    state.uri = userData.uri;
    state.imagePath = userData.imagePath;
  });

  useSettingsStore().$patch((state) => {
    state.isNew = userData.isNew;
    state.offersOnlineAdvice = userData.offersOnlineAdvice;
    state.averageResponseTime = userData.averageResponseTime;
  });
};
