<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useProfileStore } from '../../stores/ProfileStore';
const { t } = useI18n();
const { displayedName } = useProfileStore();
import { LINK_TOS } from '@/modules/rating-detail/constants/Links';
</script>

<template>
  <div class="flex flex-col md:flex-row justify-between gap-y-1 pb-4 border-b mb-4">
    <h1 class="text-base/[22px] font-bold mb-0">{{ t('heading', { name: displayedName }) }}</h1>
    <div>
      <a :href="LINK_TOS" class="inline-block whitespace-nowrap font-semibold text-sm/[22px]">
        {{ t('ratingGuidelinesIntro') }}
        <span class="sr-only">{{ t('openNewTab') }}</span>
      </a>
    </div>
  </div>
</template>
