import { PracticeArea } from '@shared/types/PracticeArea';

export const MAX_VALUE_TEXT_TITLE_LIMIT = 100;
export const MAX_VALUE_TEXTAREA_MESSAGE = 3000;

export const DEFAULT_PRACTICE_AREA: PracticeArea = {
  id: -1,
  name: 'Allgemeine Rechtsberatung',
  isSpecial: false,
};
