import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { RatingSummary } from '@shared/types/RatingSummary';
import { ReviewList } from '@shared/types/Review';

export const initRating = (ratingSummary: RatingSummary, reviewList: ReviewList) => {
  const ratingStore = useRatingStore();

  ratingStore.$patch((state) => {
    state.rating = { ...ratingSummary };
    state.reviewList = [...reviewList];
  });
};
