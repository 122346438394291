<script setup lang="ts">
import ReviewItem from '@/modules/rating-detail/components/Review/ReviewItem.vue';
import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { storeToRefs } from 'pinia';
import { AnwPlaceholder } from '@anwalt.de/tailwind-design-system';

const { reviewList, loading } = storeToRefs(useRatingStore());
</script>

<template>
  <div v-if="!loading">
    <ReviewItem
      class="mb-2 last-of-type:mb-0"
      v-for="(review, index) in reviewList"
      :key="review.id"
      :review="review"
      :index="index" />
  </div>
  <div v-else>
    <AnwPlaceholder class="mt-5 h-full" appearance="avatar"></AnwPlaceholder>
    <AnwPlaceholder class="mt-5 h-full" appearance="generic"></AnwPlaceholder>
  </div>
</template>
